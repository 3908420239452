<template>
  <layout-template>
    <template slot="main">
      <list-template>
        <template v-slot:header>
          <div class="flex flex-between-center container header">
            <div>
              <div class="nickname">请选择</div>
              <div class="department">{{buildingName}}</div>
            </div>
          </div>
        </template>
        <base-scroll ref="bs" mode="native" method="get" :load-data-url="`/h5/place/getChildrenWithoutChildrenAndParent/${id}`" @choose="onCheck">
          <template v-slot="{rows}">
            <div class="block flex flex-between-center" v-for="(row,index) in rows" :key="row.id" :data-index="index">
              <div>{{row | placeTypeFormator}}</div>
              <img src="@/assets/arrow_right.svg" class="icon"/>
            </div>
          </template>
        </base-scroll>
      </list-template>
    </template>
  </layout-template>
</template>

<script>
  import { mapGetters } from 'vuex'
  import BaseScroll from '@/components/base-scroll'
  const placeTypeFormator = row => {
    if (row && row.name) {
      const suffix = /^[0-9]*$/.test(row.name) ? ['', '单元', '楼层', '号'][row.level] : ''
      return row.name + suffix
    } else {
      return ''
    }
  }
  export default {
    data () {
      return {
        buildingName: localStorage.getItem('name')
      }
    },
    name: 'process',
    props: ['id'],
    components: { BaseScroll },
    filters: {
      placeTypeFormator
    },
    watch: {
      id (pre, cur) {
        console.log('change', pre)
      }
    },
    methods: {
      onCheck (row) {
        if (row.level === 2) {
          this.$store.dispatch('SetFloor', row)
          this.$router.push({ name: 'room' })
        } else {
          this.$router.push({ name: 'process', params: { id: row.id } })
        }
      }
    }
  }
</script>
<style scoped lang="stylus">
  .header
    padding 0 32rpx
  .nickname
    font(32,48,#fff)
    margin-bottom 8rpx
  .department
    font(24,40,rgba(255,255,255,.65))
    ellipse(500)
  .logout
    width:128rpx;
    height:48rpx;
    background:rgba(255,255,255,0.35);
    border-radius:24rpx;
    font(24,40,#fff)
  .block
    background #fff
    border-radius 20rpx
    margin 40rpx 32rpx
    height 112rpx
    padding 0 32rpx
    font(28,44,$text)
</style>
